import { setContentUrl, setHostPort } from "./actions/actions";
import getTime from './time-formatter';

// default timeout value for all the requests
let defaultTimeout = 10000;
// default value for cache control. the value its equivalent to an year.
const defaultCacheControl = "public, max-age=31536000";
// android native:
let androidUrl = "http://10.0.2.2:4500";
// default native:
let baseURl = "http://localhost:4500";

let hostPort;
let contentUrl;
let disableGeoLocation;

function getHostAndPort() {
    var arr = window.location.href.split("/");
    return arr[0] + "//" + arr[2];
}

/**
 * returns public, max-age=31536000, when no Cache Control is defined on the bootproperties (overlaying resource).
 */
function getDefaultCacheControlHeader() {
    let cacheControl;
    try {
        cacheControl = document.getElementById("cacheControl").innerText;
    } catch (e) {

    }
    return cacheControl ? cacheControl : defaultCacheControl;
};

/**
 * [HttpClientService Class that abstracts httpClient implementation.]
 */
class HttpClientService {

    /**
     * Constructor.
     */
    constructor() {}

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }

    /**
     * RESPONSE SCHEMA:
     * {
        // `data` is the response that was provided by the server
        data: {},

        // `status` is the HTTP status code from the server response
        status: 200,

        // `statusText` is the HTTP status message from the server response
        statusText: 'OK',

        // `headers` the headers that the server responded with
        // All header names are lower cased
        headers: {},

        // `config` is the config that was provided to `axios` for the request
        config: {},

        // `request` is the request that generated this response
        // It is the last ClientRequest instance in node.js (in redirects)
        // and an XMLHttpRequest instance the browser
        request: {}
      }
     */

    /**
     * Defines the httpClient provider to be used in the application.
     * @param httpClientProvider - instance of the httpClient provider.
     */
    setHttpClientProvider(httpClientProvider) {
        let store = this.store;
        let defaultTimeoutConfig;
        try {
            defaultTimeoutConfig = document.getElementById("httpRequestTimeout").innerText;
        } catch (e) {

        }

        if (defaultTimeoutConfig) {
            defaultTimeout = JSON.parse(defaultTimeoutConfig);
        }

        httpClientProvider.setDefaultContentUrl(contentUrl);
        httpClientProvider.setDefaultHostPort(hostPort);
        httpClientProvider.setStore(store);
        this.httpClientProvider = httpClientProvider;
    }

    /**
     * 
     * @param {*} platform 
     * @param {*} HTTP_HOST_PORT 
     */
    setHostAndContent(platform, HTTP_HOST_PORT) {
        this.platform = platform;

        try {
            contentUrl = document.getElementById("baseUrl").innerText;
        } catch (err) {
            contentUrl = "/content/digitaljourney";
        }

        try {
            disableGeoLocation = (document.getElementById("disableGeoLocation").innerText === "true");
        } catch (err) {
            disableGeoLocation = false;
        }

        if (platform.startsWith("web-")) {
            try {
                hostPort = document.getElementById("runMode").innerText !== "dev" ? getHostAndPort() : baseURl;
            } catch (err) {
                hostPort = baseURl;
            }
        } else {
            if (HTTP_HOST_PORT) {
                hostPort = HTTP_HOST_PORT;
            } else {
                hostPort = platform === "android" ? androidUrl : baseURl;
            }

        }
        this.store.dispatch(setContentUrl(contentUrl));
        this.store.dispatch(setHostPort(hostPort));
    }

    /**
     * 
     * @param {*} store 
     */
    setStore(store) {
        this.store = store;
    }

    /**
     * 
     * @param {*} geoLocation 
     */
    setGeoLocationService(geoLocation) {
        this.geoLocation = geoLocation;
    }

    /**
     * 
     */
    getDefaultUrl() {
        return defaultUrl;
    }

    /**
     * 
     */
    refreshToken() {
        return this.httpClientProvider.refreshToken();
    }

    /**
     *
     * @param timeout
     */
    setDefaultTimeout(timeout) {
        defaultTimeout = timeout;
    }

    /**
     *
     * @param method
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     * @returns {*}
     */
    request(method, url, headers, params, data, timeout = defaultTimeout, responseType) {

        if (headers && typeof headers !== "object")
            new Error("headers is an object! Example: headers: {'Accept': 'application/json','Content-Type':");

        if (params && typeof params !== "object")
            new Error("parameters should be of object type");

        if (!method)
            this.get(url, headers, params);

        switch (method.toLowerCase()) {
            case "get":
                if (url.indexOf("http") != -1) {
                    return this.get(`${url}`, headers, params, timeout, responseType);
                }
                return this.get(`${hostPort}${url}`, headers, params, timeout, responseType);
            case "post":
                if (url.indexOf("http") != -1) {
                    return this.post(`${url}`, headers, params, data, timeout, responseType, auth);
                }
                return this.post(`${hostPort}${url}`, headers, params, data, timeout, responseType, auth);
            case "put":
                if (url.indexOf("http") != -1) {
                    return this.put(`${url}`, headers, params, data, timeout, responseType);
                }
                return this.put(`${hostPort}${url}`, headers, params, data, timeout, responseType);
            case "delete":
                if (url.indexOf("http") != -1) {
                    return this.delete(`${url}`, headers, params, timeout, responseType);
                }
                return this.delete(`${hostPort}${url}`, headers, params, timeout, responseType);
            case "patch":
                if (url.indexOf("http") != -1) {
                    return this.patch(`${url}`, headers, params, data, timeout, responseType);
                }
                return this.patch(`${hostPort}${url}`, headers, params, data, timeout, responseType);
            case "options":
                if (url.indexOf("http") != -1) {
                    return this.options(`${url}`, headers, params, timeout, responseType);
                }
                return this.options(`${hostPort}${url}`, headers, params, timeout, responseType);

            default:
                return new Error("Operation Not supported. Supported operations are: GET/ POST/ PUT/ DELETE/ PATCH");
        }
    }

    /**
     * 
     * @param {*} url 
     */
    checkRunMode(url) {
        if (!url.includes("${runMode}"))
            return url;

        let runMode = this.store.getState().shell.runMode;

        if (runMode !== "") {
            return url.replace("${runMode}", "_" + runMode + "_");
        } else {
            return url.replace("${runMode}", "");
        }
    }

    /**
     * 
     */
    getDefaultHeaders() {
        let response;
        let cacheControl = getDefaultCacheControlHeader();
        response = {
            Accept : "application/json",
            CONVERSATION_ID : this.guid(),
            REQUEST_TIMESTAMP : getTime(),
            PLATFORM : this.platform,
            "Cache-Control" : cacheControl
        };
        if(cacheControl.includes("no-cache")){
            response.Pragma = "no-cache";
            response.Expires = -1
        }

        if (this.store && this.store.getState().interactions && this.store.getState().interactions.currentInteraction && this.store.getState().interactions.currentInteraction.id !== -1) {
            response = Object.assign(response, {CONTEXT_ID : this.store.getState().interactions.currentInteraction.id});
        }
        if (this.store && this.store.getState().shell.canvasName && this.store.getState().shell.canvasName !== "") {
            response = Object.assign(response, {CHANNEL : this.store.getState().shell.canvasName});
        }
        if (this.store && this.store.getState().shell.accessToken) {
            response = Object.assign(response, {Authorization : "Bearer " + this.store.getState().shell.accessToken});
        }
        if (this.store && this.store.getState().interactions && this.store.getState().interactions.currentInteraction && this.store.getState().interactions.currentInteraction.currentJourney) {
            let identifier = null;
            if (!this.store.getState().interactions.currentInteraction.currentJourney.subtitle) {
                identifier = null;
            }
            response = Object.assign(response, {CORRELATION_STEP_IDENTIFIER : identifier});
        }
        if (!disableGeoLocation && this.geoLocation) {
            let result = this.geoLocation.getPosition();
            if (result)
                response = Object.assign(response, !result.message ? {GEO_LOCATION : result.latitude + ", " + result.longitude} : {GEO_LOCATION : result.message});
        }
        return response;
    }

    /**
     * 
     * @param {*} headers 
     */
    setHeaders(headers) {
        let response;
        let cacheControl = getDefaultCacheControlHeader();
        if (!headers) {
            response = {
                Accept : "application/json",
                CONVERSATION_ID : this.guid(),
                REQUEST_TIMESTAMP : getTime(),
                PLATFORM : this.platform,
                "Cache-Control" : cacheControl
            };
            if(cacheControl.includes("no-cache")){
                response.Pragma = "no-cache";
                response.Expires = -1
            }
        } else {
            response = Object.assign(headers, {
                Accept : "application/json",
                CONVERSATION_ID : this.guid(),
                REQUEST_TIMESTAMP : getTime(),
                PLATFORM : this.platform,
                "Cache-Control" : cacheControl
            });
            if(cacheControl.includes("no-cache")){
                response.Pragma = "no-cache";
                response.Expires = -1
            }
        }

        if (this.store && this.store.getState().interactions && this.store.getState().interactions.currentInteraction && this.store.getState().interactions.currentInteraction.id !== -1) {
            response = Object.assign(response, {CONTEXT_ID : this.store.getState().interactions.currentInteraction.id});
        }
        if (this.store && this.store.getState().shell.canvasName && this.store.getState().shell.canvasName !== "") {
            response = Object.assign(response, {CHANNEL : this.store.getState().shell.canvasName});
        }
        if (this.store && this.store.getState().shell.accessToken && (!headers || !headers["Authorization"])) {
            response = Object.assign(response, {Authorization : "Bearer " + this.store.getState().shell.accessToken});
        }
        if (this.store && this.store.getState().interactions && this.store.getState().interactions.currentInteraction && this.store.getState().interactions.currentInteraction.currentJourney) {
            let identifier = null;
            if (!this.store.getState().interactions.currentInteraction.currentJourney.subtitle) {
                identifier = null;
            }
            response = Object.assign(response, {CORRELATION_STEP_IDENTIFIER : identifier});
        }
        if (!disableGeoLocation && this.geoLocation) {
            let result = this.geoLocation.getPosition();
            if (result)
                response = Object.assign(response, !result.message ? {GEO_LOCATION : result.latitude + ", " + result.longitude} : {GEO_LOCATION : result.message});
        }
        return response;
    }

    /**
     *
     * @param url
     * @param header
     * @param params
     * @param timeout
     * @param responseType
     */
    get(url, headers, params, timeout = defaultTimeout, responseType) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.get(`${url}`, headers, params, timeout, responseType);
        }
        return this.httpClientProvider.get(`${hostPort}${url}`, headers, params, timeout, responseType);
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    post(url, headers, params, data, timeout = defaultTimeout, responseType, auth) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.post(`${url}`, headers, params, data, timeout, responseType, auth);
        }
        return this.httpClientProvider.post(`${hostPort}${url}`, headers, params, data, timeout, responseType, auth);
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    put(url, headers, params, data, timeout = defaultTimeout, responseType) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.put(`${url}`, headers, params, data, timeout, responseType);
        }
        return this.httpClientProvider.put(`${hostPort}${url}`, headers, params, data, timeout, responseType);
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param timeout
     * @param responseType
     */
    delete(url, headers, params, timeout = defaultTimeout, responseType) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.delete(`${url}`, headers, params, timeout, responseType);
        }
        return this.httpClientProvider.delete(`${hostPort}${url}`, headers, params, timeout, responseType);
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param data
     * @param timeout
     * @param responseType
     */
    patch(url, headers, params, data, timeout = defaultTimeout, responseType) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.patch(`${url}`, headers, params, data, timeout, responseType);
        }
        return this.httpClientProvider.patch(`${hostPort}${url}`, headers, params, data, timeout, responseType);
    }

    /**
     *
     * @param url
     * @param headers
     * @param params
     * @param timeout
     * @param responseType
     */
    options(url, headers, params, timeout = defaultTimeout, responseType) {
        headers = this.setHeaders(headers);
        url = this.checkRunMode(url);
        if (url.indexOf("http") != -1) {
            return this.httpClientProvider.options(`${url}`, headers, params, timeout, responseType);
        }
        return this.httpClientProvider.options(`${hostPort}${url}`, headers, params, timeout, responseType);
    }

}

export default HttpClientService;
