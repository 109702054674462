export default function getTime() {
    var date = yearMonthDay();
    var time = dayTime();
    return date + ' ' + time;
}

function yearMonthDay() {
    var now = new Date();
    var y = now.getUTCFullYear();
    var m = now.getUTCMonth() + 1;
    var d = now.getUTCDate();
    var mm = m < 10 ? '0' + m : m;
    var dd = d < 10 ? '0' + d : d;
    return '' + y + '-' + mm + '-' + dd;
}

function dayTime() {
    var date = new Date();
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes();
    var seconds = date.getUTCSeconds();
    var elapsed = Math.round(Date.now() / 100);
    var sss = (elapsed / 1000).toString().substring(8,(elapsed / 1000).toString().length)
    return hours + ':' + minutes + ':' + seconds + sss;
}